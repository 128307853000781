import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

const SEO = ({title, description, lang, keywords}) => (
  <StaticQuery
    query={defaultsQuery}
    render={
      ({site}) => {
        const metaTitle = title || site.siteMetadata.title;
        const metaDescription = description || site.siteMetadata.description;
        const metaKeywords = keywords || ['dekoding'];

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
              {
                name: `viewport`,
                content: `minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no`,
              },
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ].concat(
              metaKeywords && metaKeywords.length ? {
                  name: `keywords`,
                  content: metaKeywords.join(', ')
                }
                : []
            )}
          />
        )
      }
    } />
);

const defaultsQuery = graphql`
  query DefaultSeo {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

SEO.defaultProps = {
  lang: `en`,
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SEO;
